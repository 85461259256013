import { useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { getUtmParams } from '@/utils';
import { getFeDeviceDetails } from '@/utils/device';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import { getAuth } from 'firebase/auth';
import { useState } from 'react';

type IError = {
  body: {
    message: string;
    authUserId?: string;
  };
  status: string;
};

function useApi<T>(
  method: string,
  url: string,
  data?: Record<string, string> | Record<string, string>[]
) {
  const [responseData, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const { id } = useAppSelector((state: RootState) => state.user.userData);
  const fetchData = async (updatedUrl?: string) => {
    setIsLoading(true);
    setData(null);
    setError(null);
    const auth = getAuth();
    const { currentUser } = auth;
    const accessToken = await currentUser?.getIdToken();
    let host: string = 'https://dev.testngg.net';
    const hostName = window.location.hostname;
    if (hostName.includes('qa1-berlin')) {
      host = 'https://qa1-berlin.now.gg';
    } else if (hostName.includes('local')) {
      host = 'https://qa1-berlin.now.gg';
    } else if (hostName.includes('now.gg') || hostName.includes('wsup.ai')) {
      host = 'https://now.gg';
    } else if (hostName.includes('stagingngg.net')) {
      host = 'https://stagingngg.net';
    }
    const options: {
      method: string;
      body?: string;
      headers: Record<string, string>;
    } = {
      method,
      headers: {
        client: 'nowgg',
        Authorization: `WsupV1 ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };
    if (data) {
      const uaId = window.localStorage.getItem('fe_uaId');
      const uaSessionId = window.sessionStorage.getItem('fe_uaSessionId');
      const feOsName = getFeDeviceDetails().feOsName;
      const feDeviceType = getFeDeviceDetails().feDeviceType;
      const utmCampaign = sessionStorage.getItem('utmCampaign');
      const utmSource = sessionStorage.getItem('utmSource');
      const utmMedium = sessionStorage.getItem('utmMedium');
      if (Array.isArray(data)) {
        data[0].utmCampaign = utmCampaign as string;
        data[0].utmSource = utmSource as string;
        data[0].utmMedium = utmMedium as string;
        data[0].uaId = uaId as string;
        data[0].uaSessionId = uaSessionId as string;
        data[0].feOsName = feOsName;
        data[0].feDeviceType = feDeviceType;
        options.body = JSON.stringify([...data]);
      } else {
        options.body = JSON.stringify({
          ...data,
          uaId,
          uaSessionId,
          feOsName,
          feDeviceType,
          utmCampaign,
          utmSource,
          utmMedium,
        });
      }
    }
    const callUrl = `${host}${updatedUrl ? updatedUrl : url}`;
    try {
      const res = await fetch(callUrl, options);
      const response = await res.json();
      if (
        response.status === 'Success' ||
        response.status === 'FailureExplicitContent' ||
        response.status === 'FailureImageTypeNotSupported' ||
        response.status === 'FailureExplicitContent'
      ) {
        setError(null);
        setTimeout(() => {
          setData(response);
        }, 100);
      } else {
        setData(null);
        setTimeout(() => {
          setError(response);
        }, 100);
      }
    } catch (e) {
      setData(null);
      setTimeout(() => {
        setError(e as IError);
        if ((e as IError).status !== 'FailureUnauthorized') {
          Bugsnag.notify(e as NotifiableError, (event) => {
            event.addMetadata('userData', {
              uaSessionId: window.sessionStorage.getItem('fe_uaSessionId'),
              uaId: window.localStorage.getItem('fe_uaId'),
              userId: id,
            });
            if (typeof window !== 'undefined') {
              event.addMetadata('clientInfo', {
                userAgent: navigator?.userAgent,
                visibilityState: document?.visibilityState,
                onLine: navigator?.onLine,
                utm: getUtmParams(),
                url: window?.location?.href,
                os: getFeDeviceDetails().feOsName,
                device: getFeDeviceDetails().feDeviceType,
              });
            }
          });
        }
      }, 100);
    }
    setIsLoading(false);
  };
  return { responseData, isLoading, error, fetchData };
}

export default useApi;
