import styles from './index.module.scss';
import loader from '/public/static/assets/images/loader.svg';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

const Loader: React.FC<{ imageSpec: number; showText?: boolean }> = ({
  imageSpec,
  showText = true,
}) => {
  const t = useTranslations();
  return (
    <div className={styles.loader}>
      <Image src={loader} alt="loader" width={imageSpec} height={imageSpec} />
      {showText && <p>{t('loading')}</p>}
    </div>
  );
};
export default Loader;
