import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ClaimedCards = {
    cardId: string;
    characterId: string;
    imageUrl: string;
    characterName: string;
    chatSessionId: string;
};

type Characters = {
    characterId: string;
    name: string;
    characterImageUrl: string;
    chatSessionCount: number;
    rank?: number;
}

export interface IUserData {
    userData: {
        name?: string,
        avatar?: string,
        id?: string,
        accessToken?: string,
        gender?: string,
        isNewUser?: boolean,
        authUserId?: string,
        isSignedIn?: boolean,
        showVerificationScreen?: boolean;
        description?: string;
        email?: string;
        age?: string;
        userVerified?: boolean;
    };
    claimedCards: ClaimedCards[];
    backLink: number;
    scrollPosition: number;
    showUserDetails: boolean;
    selectedSlide: number;
    redirectUrl: string;
    characters: Characters[];
    isNSFWEnabled: boolean;
}

const initialState: IUserData = {
    userData: {
        name: '',
        avatar: '',
        id: '',
        accessToken: '',
        gender: '',
        isNewUser: false,
        authUserId: '',
        isSignedIn: false,
        showVerificationScreen: false,
        description: '',
        email: '',
        age: '',
        userVerified: true,
    },
    claimedCards: [],
    backLink: 1,
    scrollPosition: 0,
    showUserDetails: false,
    selectedSlide: 1,
    redirectUrl: '',
    characters: [],
    isNSFWEnabled: false,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<typeof initialState.userData>) => {
            state.userData = { ...state.userData, ...action.payload };
        },
        updateUserData: (state, action: PayloadAction<typeof initialState.userData>) => {
            state.userData = { ...state.userData, ...action.payload };
        },
        setClaimedCards: (state, action: PayloadAction<typeof initialState.claimedCards>) => {
            state.claimedCards = action.payload
        },
        updateClaimedCards: (state, action: PayloadAction<ClaimedCards>) => {
            state.claimedCards = [...state.claimedCards, action.payload]
        },
        updateBackLink: (state, action: PayloadAction<number>) => {
            state.backLink = action.payload;
        },
        updateScrollPosition: (state, action: PayloadAction<number>) => {
            state.scrollPosition = action.payload;
        },
        setUserDetailsScreen: (state, action: PayloadAction<boolean>) => {
            state.showUserDetails = action.payload;
        },
        setSelectedSlide: (state, action: PayloadAction<number>) => {
            state.selectedSlide = action.payload;
        },
        setRedirectUrl: (state, action: PayloadAction<string>) => {
            state.redirectUrl = action.payload;
        },
        setCharacters: (state, action: PayloadAction<Characters[]>) => {
            state.characters = action.payload;
        },
        updateCharacters: (state, action: PayloadAction<Characters>) => {
            state.characters = [...state.characters, action.payload]
        },
        updateNSFWValue: (state, action: PayloadAction<boolean>) => {
            state.isNSFWEnabled = action.payload
        },
    },
});

export const { setUserData, setRedirectUrl, updateUserData, setCharacters, updateCharacters, setSelectedSlide, setClaimedCards, updateClaimedCards, updateBackLink, updateScrollPosition, setUserDetailsScreen, updateNSFWValue } = userSlice.actions;
export const userReducer = userSlice.reducer;