const macIntelKeyword = 'MacIntel';

export const isMacIntelPlatform = () => macIntelKeyword.indexOf(navigator.platform) !== -1;


let isMobileComputed: boolean | undefined;
export const isMobile = () => {
    if (typeof isMobileComputed === 'undefined') {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
            /Opera Mini/i,
            /IEMobile/i,
            /WPDesktop/i,
        ];
        isMobileComputed = toMatch.some(
            (toMatchItem) =>
                navigator.userAgent.match(toMatchItem) ||
                /iPad|iPhone|iPod/.test(navigator.platform) ||
                (isMacIntelPlatform() && navigator.maxTouchPoints > 1),
        );
    }
    return isMobileComputed;
};

export const isTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const tablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
            .test(userAgent);
    return tablet;
};

export const isIPad = () => {
    if (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        isMacIntelPlatform()) {
        return true;
    }
    return false;
};

const isBluestacks = () => {
    return sessionStorage.getItem('utmSource') === 'bluestacks';
}

export const isDesktop = () => {
    if (typeof window === 'undefined') {
        return null;
    }
    return isBluestacks() || (!isMobile() && !isTablet() && !isIPad());
}

export const allOsNames = {
    mac: 'mac',
    window: 'window',
    ios: 'ios',
    android: 'android',
    chrome_os: 'chrome_os',
    unix: 'unix',
    beos: 'beos',
    amigaos: 'amigaos',
    linux: 'linux',
};

const defaultDeviceData = {
    os: {
        family: '',
    },
    version: '',
    description: '',
};

let deviceData: typeof defaultDeviceData;

try {
    import('platform').then((pt) => {
        deviceData = pt.parse(window.navigator.userAgent) as typeof defaultDeviceData;
    }).catch(() => { });
} catch (e) {
    deviceData = defaultDeviceData;
}

export const isChromeOS = () => (/\bCrOS\b/.test(navigator.userAgent));

export const isUnixOS = () => (navigator?.userAgent?.indexOf('X11') !== -1);

export const isBeOS = () => (/BeOS/.test(navigator.userAgent));

export const isAmigaOS = () => (/AmigaOS/.test(navigator.userAgent));

export const getOS = () => {
    const userPlatform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', macIntelKeyword, 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    const androidPlatforms = ['Android', 'Linux'];
    let os: string = deviceData?.os?.family || 'other';

    if (iosPlatforms.indexOf(userPlatform) !== -1) {
        os = allOsNames.ios;
    } else if (macosPlatforms.indexOf(userPlatform) !== -1) {
        os = allOsNames.mac;
    } else if (windowsPlatforms.indexOf(userPlatform) !== -1) {
        os = allOsNames.window;
    } else if (
        androidPlatforms.indexOf(userPlatform) !== -1 ||
        os === 'Android'
    ) {
        os = allOsNames.android;
    } else if (isChromeOS()) {
        os = allOsNames.chrome_os;
    } else if (isUnixOS()) {
        os = allOsNames.unix;
    } else if (isBeOS()) {
        os = allOsNames.beos;
    } else if (isAmigaOS()) {
        os = allOsNames.amigaos;
    }

    return os;
};

export const isTV = () => {
    try {
        const userAgent = navigator.userAgent.toLowerCase();
        const tvKeywords = ['smarttv', 'googletv', 'appletv', 'hbbtv',
            'netcast', 'webos', 'tizen', 'viera', 'roku', 'firetv'];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tvKeywords.length; ++i) {
            if (userAgent.indexOf(tvKeywords[i]) > -1) {
                return true;
            }
        }

        if (!(isMobile() || isIPad() || isTablet()) && userAgent.indexOf('jiopages') > -1) {
            return true;
        }

        return false;
    } catch (e) {
        return false;
    }

};

export type IFeDeviceType = 'smartphone' | 'tablet' | 'other' | 'desktop';

export const getFeDeviceDetails = () => {
    const desktopSpecificOSNames = [allOsNames.mac, allOsNames.window,
    allOsNames.chrome_os, allOsNames.unix, allOsNames.beos, allOsNames.amigaos, allOsNames.linux];

    let feDeviceType: IFeDeviceType = 'other';
    const feOsName = getOS();
    if (isIPad() || isTablet()) {
        feDeviceType = 'tablet';
    } else if (isMobile()) {
        feDeviceType = 'smartphone';
    } else if (desktopSpecificOSNames.indexOf(feOsName?.toLowerCase()) !== -1 && !isTV()) {
        feDeviceType = 'desktop';
    }

    return { feDeviceType, feOsName };
};

export const isSafari = () => {
    const match = navigator.vendor.match(/[Aa]+pple/);
    if (match && match.length > 0) {
        return true;
    }
    return false;
};
