export const getAuthHost = (): string => {
    let host: string = 'https://dev.testngg.net';
    const hostName = window.location.hostname;
    if (hostName.includes('now.gg')) {
        host = 'https://now.gg';
    } else if (hostName.includes('stagingngg.net')) {
        host = 'https://stagingngg.net';
    } else if (hostName.includes('testngg.net')) {
        host = 'https://dev.testngg.net';
    } else if (hostName.includes('sandbox-now.gg')) {
        host = 'https://sandbox-now.gg';
    } else if (hostName.includes('wsup.ai')) {
        host = 'https://wsup.ai';
    }
    return host;
};

export const getApiHost = (): string => {
    let host: string = 'https://dev.testngg.net';
    const hostName = window.location.hostname;
    if (hostName.includes('qa1-berlin')) {
        host = 'https://qa1-berlin.now.gg';
    } else if (hostName.includes('local')) {
        host = 'https://qa1-berlin.now.gg';
    } else if (hostName.includes('now.gg') || hostName.includes('wsup.ai')) {
        host = 'https://now.gg';
    } else if (hostName.includes('stagingngg.net')) {
        host = 'https://stagingngg.net';
    }
    return host;
};
export const getReleaseStage = (): string => {
    let releaseStage: string = 'Dev';
    const hostName = window.location.hostname;
    if (hostName.includes('qa1-berlin')) {
        releaseStage = 'QA';
    } else if (hostName.includes('local')) {
        releaseStage = 'Dev';
    } else if (hostName.includes('now.gg') || hostName.includes('wsup.ai')) {
        releaseStage = 'Prod';
    } else if (hostName.includes('stagingngg.net')) {
        releaseStage = 'Staging';
    }
    return releaseStage;
}
